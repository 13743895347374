.appointment-view {
  padding-top: var(--content-padding-top);
  display: flex;
  margin: 0 1rem;

  &-left {
    flex: 0 0 30rem;
    padding-right: 1rem;
  }

  &-right {
    flex: auto;
    border-left: 1px solid #dfdfdf;
    padding-left: 1rem;
  }

  &-details {
    display: flex;
    margin-bottom: 2rem;

    &__section {
      margin-right: 3rem;

      & > div {
        margin-bottom: 0.2rem;
      }
    }

    &__actions {
      margin-left: auto;
      display: flex;
      align-items: center;

      button {
        margin-left: 1.5rem;
      }
    }

    &__join-appointment {
      margin-left: auto;
    }
  }

  &__banner {
    border: 1px solid var(--color-primary);
    border-radius: 7px;
    padding: 1rem;
    color: var(--color-primary);
    font-size: 1.8rem;
    display: flex;
    align-items: center;
  }

  &__banner-additional-text {
    font-style: italic;
    font-size: 1.3rem;
  }

  &__banner-actions {
    margin-left: auto;
    text-align: right;
  }

  &__banner-action {
    margin-left: 1rem;
    margin-bottom: 0;
    min-width: 12rem;
  }
}
