.password-and-confirm {
  &__password {
    display: flex;
    align-items: flex-start;
  }

  &__input {
    flex: 1;
  }

  &__icon {
    margin-top: 3rem;
    font-size: 2.2rem;
  }
}
