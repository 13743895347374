.home-week {
  margin-bottom: 5rem;

  &__button {
    margin-right: 8px;
  }

  &__table {
    border-collapse: collapse;

    & th,
    & td {
      border: 1px solid black;
      border-collapse: collapse;
    }

    & th,
    & td {
      padding: 7px;
    }

    & tr.odd td {
      background: #efefef;
    }

    & tr.today td {
      background: #6cb9bb !important;
    }

    &-date {
      width: 12rem;
    }

    &-time {
      width: 11rem;
    }

    &-name {
      width: 20rem;
    }

    &-status {
      width: 14rem;
    }

    &-paid {
      width: 7rem;
    }

    &-patient {
      width: 40rem;
    }

    &-view-appointment {
      width: 18rem;
      text-align: center;
    }

    &-view-delete {
      width: 12rem;
      text-align: center;
      color: var(--color-error-text);

      span {
        cursor: pointer;
        display: block;
      }
    }
  }
}
