.appointment-view-sidebar {
  &-patient {
    &__name {
      font-size: 2rem;
      margin-bottom: 0.5rem;
    }

    &__number {
      margin-bottom: 1rem;
    }

    &-medical {
      margin-bottom: 1rem;

      &__title {
        font-size: 1.3rem;
        display: flex;
        color: var(--color-primary);
        margin-bottom: 0.5rem;
      }

      &__item {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
      }

      &__icon {
        font-size: 2rem;
        margin-right: 1rem;

        &--checked {
          color: #8bc34a;
        }

        &--none {
          color: #eb5757;
        }
      }
    }
  }

  &-details {
    display: flex;
    margin-bottom: 2rem;

    &__section {
      margin-right: 3rem;

      & > div {
        margin-bottom: 0.2rem;
      }
    }

    &__actions {
      margin-left: auto;
      display: flex;
    }

    &__join-appointment {
      margin-left: auto;
    }
  }

  &-main-content {
  }

  &__banner {
    border: 1px solid var(--color-primary);
    border-radius: 7px;
    padding: 1rem;
    color: var(--color-primary);
    font-size: 1.8rem;
    display: flex;
    align-items: center;
  }

  &__banner-additional-text {
    font-style: italic;
    font-size: 1.3rem;
  }

  &__banner-actions {
    margin-left: auto;
    text-align: right;
  }

  &__banner-action {
    margin-left: 1rem;
    margin-bottom: 0;
    min-width: 12rem;
  }
}
