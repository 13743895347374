.add-schedules {
  padding-top: var(--content-padding-top);
  &__row {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
    align-items: center;
  }
  &__label {
    width: 300px;
    margin-right: 10px;
  }
  &__button {
    margin: 6px;
  }
  &__action_button {
    margin: 12px;
  }
  &__action_label {
    margin: 12px;
  }
  &__action_container {
    width: 500px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  &__list {
    margin-left: 20px;
  }
  &__table_row {
    display: flex;
    flex-direction: row;
  }
  &__table_cell {
    width: 150px;
  }
  &__table_wide_cell {
    width: 300px;
  }
  &__table_header {
    width: 150px;
    font-weight: bolder;
  }
  &__table_wide_header {
    width: 300px;
    font-weight: bolder;
  }
  &__table {
    margin: 10px;
  }
}
