.schedules {
  padding-top: var(--content-padding-top);

  &__row {
    flex-direction: row;
    display: flex;
    margin: 8px;
  }

  &__header {
    display: flex;
    margin-bottom: 4rem;
  }

  &__tabs {
    display: flex;
    margin-bottom: 2rem;

    &--months {
      margin-bottom: 0;
    }
  }

  &__tab {
    margin-right: 4rem;
    cursor: pointer;
    font-size: 1.6rem;
    padding-bottom: 3px;

    &--active {
      border-bottom: 3px solid var(--color-primary);
    }
  }

  &__actions {
    margin-left: auto;
    align-self: center;

    a {
      display: inline-block;
      margin-left: 2rem;
    }
  }
}
