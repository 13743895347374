@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap);
:root{--color-primary: #50bcbd;--color-primary-darker: #1ba0a2;--color-text-primary: #545454;--color-error-text: #bd5050;--color-error-background: #ffefef;--color-disabled: #949394;--width-desktop: 69rem;--content-padding-top: 9.5rem}*,*::after,*::before{margin:0;padding:0;box-sizing:inherit}html{font-size:62.5%}body{box-sizing:border-box;background:var(--color-background);font-family:"Montserrat",sans-serif;font-weight:400;font-size:1.4rem;line-height:1.8rem;color:#545454;color:var(--color-text-primary)}a{color:#50bcbd;color:var(--color-primary);text-decoration:none}strong{font-weight:600}

.loading-spinner{min-height:4.8rem;display:flex;justify-content:center;align-items:center}.loading-spinner--fullscreen{width:100%;height:100vh}.loading-spinner__dots{margin:0 auto 0;width:70px;text-align:center}.loading-spinner__bounce{width:18px;height:18px;background-color:var(--color-primary);border-radius:100%;display:inline-block;-webkit-animation:loading-spinner-bounce 1.4s infinite ease-in-out both;animation:loading-spinner-bounce 1.4s infinite ease-in-out both}.loading-spinner__bounce--1{-webkit-animation-delay:-0.32s;animation-delay:-0.32s}.loading-spinner__bounce--2{-webkit-animation-delay:-0.16s;animation-delay:-0.16s}@-webkit-keyframes loading-spinner-bounce{0%,80%,100%{-webkit-transform:scale(0)}40%{-webkit-transform:scale(1)}}@keyframes loading-spinner-bounce{0%,80%,100%{-webkit-transform:scale(0);transform:scale(0)}40%{-webkit-transform:scale(1);transform:scale(1)}}
.activate-account{margin:0 auto;max-width:var(--width-desktop-smaller)}.activate-account-content{padding-top:var(--content-padding-top) !important}.activate-account-actions{margin-top:5rem;display:block}
.content{padding:0 2rem;margin:0 auto;max-width:var(--width-desktop)}.content--full-width{max-width:98%}
.page-header{display:flex;align-items:center;padding-right:1rem;padding-left:1rem;padding-bottom:1rem;position:fixed;width:100%;background:#fff;top:0;left:0;border-bottom:1px solid #dfdfdf}.page-header__left{display:flex;align-items:center;min-width:30rem}.page-header__back{margin-right:.5rem;cursor:pointer;color:var(--color-primary);font-size:2.4rem;order:1;margin-top:1rem}.page-header__title{margin:1rem 0 0 0 !important;order:2;font-size:2rem}.page-header__logo{background-color:rgba(0,0,0,0);border:none;margin:1rem 1rem 0 0;width:7rem;order:0}.page-header-info{border:1px solid var(--color-primary);border-radius:5px;color:var(--color-primary);padding:.5rem 1rem 1rem 1rem;display:flex;border-radius:10px;margin:.5rem auto 0 auto}.page-header-info__block{margin-right:8rem}.page-header-info__block:last-child{margin-right:0}.page-header-info__title{margin-bottom:1rem}.page-header-info__time{font-size:3rem}.page-header-info__extra{margin-bottom:.5rem}
.navigation-nav-bar{display:flex;background:#fff;margin-right:2rem;margin-left:auto;width:auto;min-width:30rem}.navigation-nav-bar__tab{display:flex;flex-direction:row;align-items:center;padding:0 .5rem 0 .5rem;flex:1 1;color:#868686;margin-left:1.4rem;cursor:pointer}.navigation-nav-bar__tab--active{color:var(--color-primary)}.navigation-nav-bar__label{font-weight:300;font-size:1.5rem;padding-left:.5rem}.navigation-nav-bar__icon{font-size:2.4rem}
.logo{display:block;width:100%}
.text-title--main{font-size:2rem;color:var(--color-primary);margin-bottom:2rem}.text-title--sub{font-size:1.6rem;color:var(--color-primary);margin-bottom:1.2rem}.text-title.mb-10{margin-bottom:1rem}
.form-error{margin-bottom:2rem;background:var(--color-error-background);color:var(--color-error-text);padding:1.5rem;border-radius:6px;white-space:pre-wrap}.form-error p{margin-bottom:1.5rem}.form-error p:last-child{margin-bottom:0}.form-error a{color:var(--color-error-text);text-decoration:underline}
.form-text-input{margin-bottom:2rem;display:flex}.form-text-input__label{font-size:1.6rem;color:var(--color-text-primary);flex:1 1}.form-text-input__field{font-size:var(--font-size-default);font-weight:400;font-family:var(--font-family);color:var(--color-text-primary);padding:.75rem 1rem;border:1px solid var(--color-primary);border-radius:7px;width:100%;display:block;min-width:100%;background:rgba(0,0,0,0);min-height:42px;margin-top:.4rem}.form-text-input__field:active,.form-text-input__field:focus{outline:none;border:2px solid var(--color-primary-darker)}.form-text-input__error{display:block;margin-top:.5rem;color:var(--color-error)}.form-text-input__remove{align-self:flex-end;margin-bottom:1rem;margin-left:1rem;cursor:pointer}
.field-error{font-size:1.3rem;margin-top:.5rem;color:var(--color-error-text)}.field-error__list{margin-left:1.3rem}
.button{border-radius:6px;font-weight:400;text-align:center;text-transform:uppercase;margin-bottom:1rem;cursor:pointer;border:none}.button--size-small{padding:.6rem 1.6rem;font-size:1.3rem;line-height:1.8}.button--size-medium{padding:.4rem 1rem;font-size:1.3rem;line-height:1.8}.button--size-large{padding:1rem 1.6rem;font-size:1.8rem;line-height:2.2}.button--color-primary{background:var(--color-primary);box-shadow:0px 2px 1px rgba(0,0,0,.2),0px 2px 2px rgba(0,0,0,.14),0px 1px 5px rgba(0,0,0,.12);color:#fff}.button--color-primary:hover{background:var(--color-primary-darker)}.button--color-disabled{background:var(--color-disabled);box-shadow:0px 2px 1px rgba(0,0,0,.2),0px 2px 2px rgba(0,0,0,.14),0px 1px 5px rgba(0,0,0,.12);color:#fff;cursor:not-allowed}.button--color-primary-outline{border:1px solid var(--color-primary);background:rgba(0,0,0,0);color:var(--color-primary)}.button--color-primary-link{border:none;background:rgba(0,0,0,0);color:var(--color-primary);text-transform:none}.button--color-secondary{background:#fff;box-shadow:0px 2px 1px rgba(0,0,0,.2),0px 2px 2px rgba(0,0,0,.14),0px 1px 5px rgba(0,0,0,.12);color:var(--color-primary)}.button--color-secondary-outline{border:1px solid #fff;background:rgba(0,0,0,0);color:#fff}.button--color-danger-outline{border:1px solid #ee6c6c;background:rgba(0,0,0,0);color:#ee6c6c}
.password-and-confirm__password{display:flex;align-items:flex-start}.password-and-confirm__input{flex:1 1}.password-and-confirm__icon{margin-top:3rem;font-size:2.2rem}
.login{margin:0 auto;max-width:var(--width-desktop-smaller)}.login-content{padding-top:var(--content-padding-top)}.login-actions{margin-top:5rem;display:block}
.forgotten-password{position:relative;margin:0 auto;max-width:var(--width-desktop-smaller)}.forgotten-password-content{padding-top:var(--content-padding-top)}
.text-body--default{font-size:1.3rem;color:var(--color-text-primary);margin-bottom:1rem}.text-body--emphasis{font-weight:bold}.text-body--small{font-size:1.5rem;font-style:italic;margin-bottom:2.2rem}.text-body ul,.text-body ol{margin:1rem 0 1.5rem 0}.text-body li{margin:0 0 1rem 1.5rem}
.home{padding-top:var(--content-padding-top)}.home__title{font-size:2rem;margin-bottom:4rem}.home__sub-title{color:var(--color-text-primary);margin-bottom:2rem}
.home-week{margin-bottom:5rem}.home-week__button{margin-right:8px}.home-week__table{border-collapse:collapse}.home-week__table th,.home-week__table td{border:1px solid #000;border-collapse:collapse}.home-week__table th,.home-week__table td{padding:7px}.home-week__table tr.odd td{background:#efefef}.home-week__table tr.today td{background:#6cb9bb !important}.home-week__table-date{width:12rem}.home-week__table-time{width:11rem}.home-week__table-name{width:20rem}.home-week__table-status{width:14rem}.home-week__table-paid{width:7rem}.home-week__table-patient{width:40rem}.home-week__table-view-appointment{width:18rem;text-align:center}.home-week__table-view-delete{width:12rem;text-align:center;color:var(--color-error-text)}.home-week__table-view-delete span{cursor:pointer;display:block}
.alert-info{background:rgba(0,0,0,.5);position:fixed;top:0;bottom:0;left:0;right:0;display:flex;align-items:center;z-index:1000}.alert-info__header{display:flex;align-items:center;margin-bottom:1.5rem}.alert-info__label{margin-bottom:0}.alert-info__close{margin-left:auto;cursor:pointer;font-size:2.4rem}.alert-info__content{position:absolute;left:1rem;right:1rem;padding:2rem 1.5rem 2rem 1.5rem;border-radius:8px;background:#fff;box-shadow:0px 5px 5px rgba(0,0,0,.2),0px 8px 10px rgba(0,0,0,.14),0px 3px 14px rgba(0,0,0,.12);overflow:auto;max-width:50rem;margin:0 auto}.alert-info__action{margin-bottom:0}
.alert-confirm{background:rgba(0,0,0,.5);position:fixed;top:0;bottom:0;left:0;right:0;display:flex;align-items:center;z-index:1000}.alert-confirm__header{display:flex;align-items:center;margin-bottom:1.5rem}.alert-confirm__label{margin-bottom:0}.alert-confirm__close{margin-left:auto;cursor:pointer;font-size:2.4rem}.alert-confirm__content{position:absolute;left:1rem;right:1rem;padding:2rem 1.5rem 2rem 1.5rem;border-radius:8px;background:#fff;box-shadow:0px 5px 5px rgba(0,0,0,.2),0px 8px 10px rgba(0,0,0,.14),0px 3px 14px rgba(0,0,0,.12);overflow:auto;max-width:50rem;margin:0 auto}.alert-confirm__actions{display:flex;align-items:center;justify-content:space-between}.alert-confirm__action{width:48%;margin-bottom:0}
.schedules{padding-top:var(--content-padding-top)}.schedules__row{flex-direction:row;display:flex;margin:8px}.schedules__header{display:flex;margin-bottom:4rem}.schedules__tabs{display:flex;margin-bottom:2rem}.schedules__tabs--months{margin-bottom:0}.schedules__tab{margin-right:4rem;cursor:pointer;font-size:1.6rem;padding-bottom:3px}.schedules__tab--active{border-bottom:3px solid var(--color-primary)}.schedules__actions{margin-left:auto;align-self:center}.schedules__actions a{display:inline-block;margin-left:2rem}
.add-schedules{padding-top:var(--content-padding-top)}.add-schedules__row{display:flex;flex-direction:row;margin-top:6px;align-items:center}.add-schedules__label{width:300px;margin-right:10px}.add-schedules__button{margin:6px}.add-schedules__action_button{margin:12px}.add-schedules__action_label{margin:12px}.add-schedules__action_container{width:500px;margin-top:10px;display:flex;flex-direction:column;justify-content:center;text-align:center}.add-schedules__list{margin-left:20px}.add-schedules__table_row{display:flex;flex-direction:row}.add-schedules__table_cell{width:150px}.add-schedules__table_wide_cell{width:300px}.add-schedules__table_header{width:150px;font-weight:bolder}.add-schedules__table_wide_header{width:300px;font-weight:bolder}.add-schedules__table{margin:10px}
.account{padding-top:var(--content-padding-top)}.account__title{margin-top:2rem}
.menu-list{margin:0 auto;max-width:var(--width-desktop)}
.menu-list__item{border-bottom:1px solid #ddd;display:flex;align-items:center;padding:1.6rem 2rem;font-size:1.6rem;cursor:pointer}.menu-list__item:last-child{border-bottom:none}.menu-list__label{margin-right:auto;color:#757575}.menu-list__icon{color:#757575;font-size:2rem}
.account-password{padding-top:var(--content-padding-top)}
.search{padding-top:var(--content-padding-top)}.search-form{margin-bottom:1rem}.search-form__term{width:100%;margin-bottom:1rem}.search-patient{margin-top:4rem}.search-patient__label{color:var(--color-text-primary)}.search-patient__button{font-size:x-small;margin-bottom:1.2rem;margin-right:1.2rem}.search-patient__table{border-collapse:collapse}.search-patient__table th,.search-patient__table td{border:1px solid #000;border-collapse:collapse}.search-patient__table th,.search-patient__table td{padding:7px}.search-patient__table tr.odd td{background:#efefef}.search-patient__table tr.today td{background:#6cb9bb !important}.search-patient__table-date{width:12rem}.search-patient__table-time{width:11rem}.search-patient__table-name{width:20rem}.search-patient__table-status{width:14rem}.search-patient__table-paid{width:7rem}.search-patient__table-patient{width:40rem}.search-patient__table-view-appointment{width:12rem;text-align:center}
.lookup{padding-top:var(--content-padding-top)}.lookup-form{margin-bottom:1rem}.lookup-form__number{width:100%;margin-bottom:1rem}.lookup-patient{margin-top:4rem}.lookup-patient__label{color:var(--color-text-primary)}.lookup-patient__button{font-size:x-small;margin-bottom:1.2rem;margin-right:1.2rem}.lookup-patient__table{border-collapse:collapse}.lookup-patient__table th,.lookup-patient__table td{border:1px solid #000;border-collapse:collapse}.lookup-patient__table th,.lookup-patient__table td{padding:7px}.lookup-patient__table tr.odd td{background:#efefef}.lookup-patient__table tr.today td{background:#6cb9bb !important}.lookup-patient__table-date{width:12rem}.lookup-patient__table-time{width:11rem}.lookup-patient__table-name{width:20rem}.lookup-patient__table-status{width:14rem}.lookup-patient__table-paid{width:7rem}.lookup-patient__table-patient{width:40rem}.lookup-patient__table-view-appointment{width:12rem;text-align:center}
.appointment-view{padding-top:var(--content-padding-top);display:flex;margin:0 1rem}.appointment-view-left{flex:0 0 30rem;padding-right:1rem}.appointment-view-right{flex:auto;border-left:1px solid #dfdfdf;padding-left:1rem}.appointment-view-details{display:flex;margin-bottom:2rem}.appointment-view-details__section{margin-right:3rem}.appointment-view-details__section>div{margin-bottom:.2rem}.appointment-view-details__actions{margin-left:auto;display:flex;align-items:center}.appointment-view-details__actions button{margin-left:1.5rem}.appointment-view-details__join-appointment{margin-left:auto}.appointment-view__banner{border:1px solid var(--color-primary);border-radius:7px;padding:1rem;color:var(--color-primary);font-size:1.8rem;display:flex;align-items:center}.appointment-view__banner-additional-text{font-style:italic;font-size:1.3rem}.appointment-view__banner-actions{margin-left:auto;text-align:right}.appointment-view__banner-action{margin-left:1rem;margin-bottom:0;min-width:12rem}
.alert-error{background:rgba(0,0,0,.5);position:fixed;top:0;bottom:0;left:0;right:0;display:flex;align-items:center;z-index:1000}.alert-error__header{display:flex;align-items:center;margin-bottom:1.5rem}.alert-error__label{margin-bottom:0}.alert-error__close{margin-left:auto;cursor:pointer;font-size:2.4rem}.alert-error__content{position:absolute;left:1rem;right:1rem;padding:2rem 1.5rem 2rem 1.5rem;border-radius:8px;background:#fff;box-shadow:0px 5px 5px rgba(0,0,0,.2),0px 8px 10px rgba(0,0,0,.14),0px 3px 14px rgba(0,0,0,.12);overflow:auto;max-width:50rem;margin:0 auto}.alert-error__action{margin-bottom:0}
.appointment-view__block{margin-bottom:2rem}.appointment-view__block-content{border:1px solid #dfdfdf;border-radius:7px;padding:1rem;margin-bottom:2rem}.appointment-view__block-title{font-size:1.6rem;margin-bottom:1rem;color:var(--color-primary)}.appointment-view__patient-note{margin-bottom:2rem}.appointment-view__patient-note p{min-height:1.3rem}.appointment-view__preview-gp-letter{margin-top:2rem}
.form-text-area{margin-bottom:1rem}.form-text-area__label{font-size:1.3rem;color:var(--color-text-primary)}.form-text-area__field{height:15rem;resize:none;font-size:var(--font-size-default);font-weight:400;font-family:var(--font-family);color:var(--color-text-primary);padding:.75rem 1rem;border:1px solid var(--color-primary);border-radius:7px;width:100%;margin-top:.4rem}.form-text-area__field:active,.form-text-area__field:focus{outline:none;border:2px solid var(--color-primary)}.form-text-area__error{display:block;margin-top:.5rem;color:var(--color-error)}
.appointment-audit-logs__table{border-collapse:collapse;table-layout:fixed;width:100%;margin-bottom:10rem}.appointment-audit-logs__table th,.appointment-audit-logs__table td{border:1px solid #000;border-collapse:collapse;vertical-align:top;text-align:left;word-wrap:break-word}.appointment-audit-logs__table th,.appointment-audit-logs__table td{padding:7px}.appointment-audit-logs__table pre.__json-pretty__{margin-bottom:2rem}.appointment-audit-logs__table pre.__json-pretty__ span{word-wrap:break-word;white-space:normal}.appointment-audit-logs__table-formatted-text{margin-bottom:2rem}.appointment-audit-logs__table-formatted-text p{min-height:1.3rem}.appointment-audit-logs__table-event{width:25%}.appointment-audit-logs__table-user{width:10%}.appointment-audit-logs__table-details{width:55%}.appointment-audit-logs__table-ip{width:10%}
.appointment-view-sidebar-patient__name{font-size:2rem;margin-bottom:.5rem}.appointment-view-sidebar-patient__number{margin-bottom:1rem}.appointment-view-sidebar-patient-medical{margin-bottom:1rem}.appointment-view-sidebar-patient-medical__title{font-size:1.3rem;display:flex;color:var(--color-primary);margin-bottom:.5rem}.appointment-view-sidebar-patient-medical__item{margin-bottom:1rem;display:flex;align-items:center}.appointment-view-sidebar-patient-medical__icon{font-size:2rem;margin-right:1rem}.appointment-view-sidebar-patient-medical__icon--checked{color:#8bc34a}.appointment-view-sidebar-patient-medical__icon--none{color:#eb5757}.appointment-view-sidebar-details{display:flex;margin-bottom:2rem}.appointment-view-sidebar-details__section{margin-right:3rem}.appointment-view-sidebar-details__section>div{margin-bottom:.2rem}.appointment-view-sidebar-details__actions{margin-left:auto;display:flex}.appointment-view-sidebar-details__join-appointment{margin-left:auto}.appointment-view-sidebar__banner{border:1px solid var(--color-primary);border-radius:7px;padding:1rem;color:var(--color-primary);font-size:1.8rem;display:flex;align-items:center}.appointment-view-sidebar__banner-additional-text{font-style:italic;font-size:1.3rem}.appointment-view-sidebar__banner-actions{margin-left:auto;text-align:right}.appointment-view-sidebar__banner-action{margin-left:1rem;margin-bottom:0;min-width:12rem}
.unsupported-browser{display:flex;flex-direction:column;align-items:center;padding-bottom:2rem;margin:5rem auto 0 auto}.unsupported-browser-info__logo{margin:2rem auto 2rem auto;max-width:17rem}.unsupported-browser-info__title{font-size:3.2rem;line-height:3.8rem;color:var(--color-primary);font-weight:300;margin:2rem 2rem 2rem 2rem;text-align:center}
.page-not-found{display:flex;flex-direction:column;align-items:center;padding-bottom:2rem;margin:5rem auto 0 auto}.page-not-found-info__logo{margin:2rem auto 2rem auto;max-width:17rem}.page-not-found-info__title{font-size:3.2rem;line-height:3.8rem;color:var(--color-primary);font-weight:300;margin:2rem 2rem 2rem 2rem;text-align:center}.page-not-found__home{display:flex;justify-content:center}
.appointment-audit-logs__table{border-collapse:collapse;table-layout:fixed;width:100%;margin-bottom:10rem}.appointment-audit-logs__table th,.appointment-audit-logs__table td{border:1px solid #000;border-collapse:collapse;vertical-align:top;text-align:left;word-wrap:break-word}.appointment-audit-logs__table th,.appointment-audit-logs__table td{padding:7px}.appointment-audit-logs__table pre.__json-pretty__{margin-bottom:2rem}.appointment-audit-logs__table pre.__json-pretty__ span{word-wrap:break-word;white-space:normal}.appointment-audit-logs__table-formatted-text{margin-bottom:2rem}.appointment-audit-logs__table-formatted-text p{min-height:1.3rem}.appointment-audit-logs__table-event{width:25%}.appointment-audit-logs__table-user{width:10%}.appointment-audit-logs__table-details{width:55%}.appointment-audit-logs__table-ip{width:10%}
