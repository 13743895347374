.form-text-area {
  margin-bottom: 1rem;

  &__label {
    font-size: 1.3rem;
    color: var(--color-text-primary);
  }

  &__field {
    height: 15rem;
    resize: none;
    font-size: var(--font-size-default);
    font-weight: 400;
    font-family: var(--font-family);
    color: var(--color-text-primary);
    padding: 0.75rem 1rem;
    border: 1px solid var(--color-primary);
    border-radius: 7px;
    width: 100%;
    margin-top: 0.4rem;

    &:active,
    &:focus {
      outline: none;
      border: 2px solid var(--color-primary);
    }
  }

  &__error {
    display: block;
    margin-top: 0.5rem;
    color: var(--color-error);
  }
}
