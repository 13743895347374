.form-text-input {
  margin-bottom: 2rem;
  display: flex;

  &__label {
    font-size: 1.6rem;
    color: var(--color-text-primary);
    flex: 1;
  }

  &__field {
    font-size: var(--font-size-default);
    font-weight: 400;
    font-family: var(--font-family);
    color: var(--color-text-primary);
    padding: 0.75rem 1rem;
    border: 1px solid var(--color-primary);
    border-radius: 7px;
    width: 100%;
    display: block;
    min-width: 100%;
    background: transparent;
    min-height: 42px;
    margin-top: 0.4rem;

    &:active,
    &:focus {
      outline: none;
      border: 2px solid var(--color-primary-darker);
    }
  }

  &__error {
    display: block;
    margin-top: 0.5rem;
    color: var(--color-error);
  }

  &__remove {
    align-self: flex-end;
    margin-bottom: 1rem;
    margin-left: 1rem;
    cursor: pointer;
  }
}
