.appointment-audit-logs {
  &__table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin-bottom: 10rem;

    & th,
    & td {
      border: 1px solid black;
      border-collapse: collapse;
      vertical-align: top;
      text-align: left;
      word-wrap: break-word;
    }

    & th,
    & td {
      padding: 7px;
    }

    & pre.__json-pretty__ {
      margin-bottom: 2rem;

      span {
        word-wrap: break-word;
        white-space: normal;
      }
    }

    &-formatted-text {
      margin-bottom: 2rem;

      p {
        min-height: 1.3rem;
      }
    }

    &-event {
      width: 25%;
    }

    &-user {
      width: 10%;
    }

    &-details {
      width: 55%;
    }

    &-ip {
      width: 10%;
    }
  }
}
