.home {
  padding-top: var(--content-padding-top);

  &__title {
    font-size: 2rem;
    margin-bottom: 4rem;
  }

  &__sub-title {
    color: var(--color-text-primary);
    margin-bottom: 2rem;
  }
}
