.search {
  padding-top: var(--content-padding-top);

  &-form {
    margin-bottom: 1rem;

    &__term {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  &-patient {
    margin-top: 4rem;

    &__label {
      color: var(--color-text-primary);
    }
    &__button {
      font-size: x-small;
      margin-bottom: 1.2rem;
      margin-right: 1.2rem;
    }

    &__table {
      border-collapse: collapse;

      & th,
      & td {
        border: 1px solid black;
        border-collapse: collapse;
      }

      & th,
      & td {
        padding: 7px;
      }

      & tr.odd td {
        background: #efefef;
      }

      & tr.today td {
        background: #6cb9bb !important;
      }

      &-date {
        width: 12rem;
      }

      &-time {
        width: 11rem;
      }

      &-name {
        width: 20rem;
      }

      &-status {
        width: 14rem;
      }

      &-paid {
        width: 7rem;
      }

      &-patient {
        width: 40rem;
      }

      &-view-appointment {
        width: 12rem;
        text-align: center;
      }
    }
  }
}
