.text-body {
  &--default {
    font-size: 1.3rem;
    color: var(--color-text-primary);
    margin-bottom: 1rem;
  }

  &--emphasis {
    font-weight: bold;
  }

  &--small {
    font-size: 1.5rem;
    font-style: italic;
    margin-bottom: 2.2rem;
  }

  ul,
  ol {
    margin: 1rem 0 1.5rem 0;
  }

  li {
    margin: 0 0 1rem 1.5rem;
  }
}
