.menu-list {
  &__item {
    border-bottom: 1px solid #dddddd;
    display: flex;
    align-items: center;
    padding: 1.6rem 2rem;
    font-size: 1.6rem;
    cursor: pointer;

    &:last-child {
      border-bottom: none;
    }
  }

  &__label {
    margin-right: auto;
    color: #757575;
  }

  &__icon {
    color: #757575;
    font-size: 2rem;
  }
}
