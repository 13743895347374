@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap');

:root {
  --color-primary: #50bcbd;
  --color-primary-darker: #1ba0a2;
  --color-text-primary: #545454;
  --color-error-text: #bd5050;
  --color-error-background: #ffefef;
  --color-disabled: #949394;

  // 900 / 13px (body font size)
  --width-desktop: 69rem;

  --content-padding-top: 9.5rem;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  /* force inheritance on all elements as box-sizing is not an inherited
  property by default */
  box-sizing: inherit;
}

html {
  font-size: 62.5%; /* This defines what 1rem is (10px) */
}

body {
  box-sizing: border-box;
  background: var(--color-background);
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: var(--color-text-primary);
}

a {
  color: var(--color-primary);
  text-decoration: none;
}

strong {
  font-weight: 600;
}
