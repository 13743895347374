.login {
  margin: 0 auto;
  max-width: var(--width-desktop-smaller);

  &-content {
    padding-top: var(--content-padding-top);
  }

  &-actions {
    margin-top: 5rem;
    display: block;
  }
}
